<template>
  <svg id="emd-mundi-icon" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Artboard-Copy" stroke="none" stroke-width="1.5" fill="none" fill-rule="evenodd">
      <path d="M9,12.0080656 L16.4698111,19.4058562 C16.5919309,19.5267986 16.7889716,19.5258441 16.909914,19.4037243 C16.9676251,19.3454514 17,19.2667528 17,19.1847388 L17,0.808501895 C17,0.636628994 16.8606695,0.497298479 16.6887966,0.497298479 C16.606069,0.497298479 16.526746,0.5302376 16.4683526,0.588838463 L7.51816513,9.57082885 C7.28013943,9.80970033 7.2756379,10.1946816 7.5080131,10.4390534 L9,12.0080656 L9,12.0080656 L10.4999421,10.4391675 C10.7335142,10.1948576 10.7294158,9.80878945 10.49071,9.56949307 L1.53152918,0.588146205 C1.41014693,0.466463648 1.21310398,0.466220196 1.09142142,0.58760244 C1.03289306,0.645986352 1,0.725258677 1,0.8079282 L1,19.1847388 C1,19.3566117 1.13933052,19.4959422 1.31120342,19.4959422 C1.39321744,19.4959422 1.47191601,19.4635673 1.53018892,19.4058562 L9,12.0080656 L9,12.0080656 Z" id="path" stroke="none"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EmdMundiIcon'
}
</script>
